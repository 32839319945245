<template>
  <div class="product-slider-images__root">
    <div class="product-slider-images">
      <swiper
        ref="productSliderImages"
        :navigation="true"
        :modules="[Navigation,Pagination]"
        class="product-slider-images__container"
        @swiper="setSwiperRef"
        @slideChange="slideChangeTransitionEnd"
      >
        <swiper-slide
          v-for="(image, idx) in allImages"
          :key="`product-slider-images-${idx}`"
          class="swiper-slide product-slider-images__slider"
        >
          <nuxt-img
            loading="lazy"
            :src="String(image.imageId)"
            :width="1200"
            :height="660"
            fit="default"
          />
        </swiper-slide>

        <!--                <div class="product-slider-images__button swiper-button-prev"></div>-->
        <!--                <div class="product-slider-images__button swiper-button-next"></div>-->
      </swiper>

      <div class="product-slider-images__count">
        {{ active + 1 }} из {{ allImages.length }}
      </div>
      <div
        class="product-slider-images__full"
        @click="openPopupGallery"
      >
        <img
          :src="SliderGlassIcon"
          alt="slider glass icon"
        >
      </div>
    </div>
    <slot name="template-for-dots" />
    <ImagesPagination
      class="images-pagination__root"
      :class="{'--collection': isCollection}"
      :images="imagesProduct"
      :active="active"
      :is-crop="true"
      :total="countImagesProduct"
      :limit-items="limitItems"
      @onChangeActive="slideSetIndex"
    />
  </div>
</template>

<script setup lang="ts">
import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperClass } from 'swiper/types';
import { Navigation, Pagination } from 'swiper/modules';
import SliderGlassIcon from 'assets/img/svg/product/slider-glass.svg';

// type productsBasePaths = string | '/categories' | '/promotions' | '/brands' | '/sets';
interface ISliderImagesProps {
    images: number[] | undefined;
    productId?: string;
    isCollection?: boolean;
    isProductCard?: boolean;
    model?: string;
			objectFit?:'contain'|'cover'
			paddingTop?:'40%'|'50%'|'55%'|'60%'|'70%'|'75%'|'90%'
}

const props = withDefaults(defineProps<ISliderImagesProps>(), {
  images: () => [],
  productId: '',
  model: 'Product',
  objectFit: 'contain',
  paddingTop: '75%',
  isCollection: false,
  isProductCard: false,
});
const emits = defineEmits<{(e: 'openPopupGallery'): void,
	(e:'activeSlide', active:number):number
}>();

const active = ref(0);
const productSliderImages = ref(0);
const swiperRef = ref<SwiperClass | null>(null);

const ImagesPagination = defineAsyncComponent(() => import('~/components/products/slider-images/SliderPaginationImages.vue'));

const allImages = computed(() => [...imagesProduct.value]);

const imagesProduct = computed(() => props?.images.map((imageId, index) => ({ index, imageId })));

const countImagesProduct = computed(() => props?.images.length);

const slideChangeTransitionEnd = (prop: SwiperClass) => {
  active.value = prop.activeIndex || 0;
  emits('activeSlide', active.value);
};
const setSwiperRef = (swiper: SwiperClass) => {
  swiperRef.value = swiper;
};
const slideSetIndex = (index:number) => {
  if (swiperRef.value) {
    swiperRef.value.slideTo(index);
  }
};

const openPopupGallery = () => {
  emits('openPopupGallery');
};

// Определяем предел изображений внизу пагинации
const limitItems = computed(() => {
  return props.isProductCard ? 6 : 12
})
</script>

<style  lang="scss">
.product-slider-images__root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product-slider-images {
  position: relative;

  &::before{
    content: '';
    float: right;
    padding-top:v-bind(paddingTop);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 16;
    pointer-events: none;
    border-radius: 7px;
  }
}

.product-slider-images__container {
  height: 100%;
  position: absolute;
  inset: 0;

}

.product-slider-images__slider {
  border-radius: 7px;
  overflow: hidden;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: v-bind(objectFit);
  }
}

.product-slider-images__count {
  background: rgb(255 255 255 / 50%);
  border-radius: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  z-index: 10;
  position: absolute;
  bottom: 12px;
  left: 12px;
  padding: 6px 10px;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.product-slider-images__full {
  background: rgb(255 255 255 / 50%);
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;

  &:hover {
    background-color: white;
  }
}

.swiper-button-prev,
.swiper-button-next {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  opacity: .5;
  background-color: #efefef;

  &::after {
    font-weight: 600;
    font-size: 20px;
    color: black;
  }

  &:hover {
    background: #f7f7f7;
    opacity: 1;
  }

  &.swiper-button-disabled {
    opacity: 0;
  }
}

.product-slider-pagination {
  display: flex;
  margin-left: -2px;
  margin-top: 13px;

  &.--open {
    flex-wrap: wrap;
  }
}

.product-slider-pagination > * {
  margin-left: 2px;
  margin-top: 2px;
  width: calc(100% / 7 - 2px);
}

.product-slider-pagination__item {
  height: 60px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgb(255 255 255 / 60%),
      rgb(255 255 255 / 60%)
    );
    position: absolute;
    inset: 0;
  }

  &:hover::after {
    content: none;
  }
}

.product-slider-pagination__more {
  border: 1px solid red;
  border-radius: 5px;
  cursor: pointer;
  font-feature-settings: 'pnum' on, 'lnum' on;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  &:hover {
    color: white;
  }
}

.images-pagination__root.--collection {
  .images-pagination__item {
    width: calc(100% / 13 - 2px);
    min-width: calc(100% / 13 - 2px);
    max-width: calc(100% / 13 - 2px);
    img {
      object-fit: cover;
    }
  }
}


@media (max-width: 1199px) {
  .images-pagination__root.--collection {
    .images-pagination {
      flex-wrap: wrap;
    }
    .images-pagination__item {
      width: calc(100% / 8 - 2px);
    }
  }
}
@media screen and (max-width: 990px) {
  .product-slider-images__slider {
    img {
      object-fit: contain;
    }
  }

  .product-slider-images__button {
    width: 32px;
    height: 32px;

    &::after {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
@media (max-width: 1023px) {
  .images-pagination__root.--collection {
    display: none;
  }
  .images-pagination__root {
    display: none;
  }
}
@media (max-width: 659px){
  .swiper-button-prev, .swiper-button-next{
    width: 32px;
    height: 32px;

    &::after{
      font-size: 16px;
    }
  }

  .product-slider-images__full{
    width: 38px;
    height: 38px;
  }

  .product-slider-images__count{
    padding: 2px;
  }
}
</style>
